var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "contact-page"
  }, [_c('h2', {
    staticClass: "marginleft-160 font-weight-bold py-5"
  }, [_vm._v("Contact Us")]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row section-b-space"
  }, [_c('div', {
    staticClass: "col-lg-5"
  }, [_c('div', {
    staticClass: "contact-right"
  }, [_c('ul', [_vm._m(0), _vm._m(1), _c('li', [_c('div', {
    staticClass: "contact-icon mt-3"
  }, [_c('b-icon-geo-alt', {
    attrs: {
      "variant": "danger"
    }
  }), _c('h6', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Address")])], 1), _vm._m(2)]), _c('li', [_c('div', {
    staticClass: "contact-icon"
  }, [_c('b-icon-hand-thumbs-up', {
    attrs: {
      "variant": "danger"
    }
  }), _c('h6', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Follow us")])], 1), _vm._m(3)])])])]), _vm._m(4)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('div', {
    staticClass: "contact-icon"
  }, [_c('img', {
    attrs: {
      "src": "img/contact/phone.png",
      "alt": "contact icon"
    }
  }), _c('h6', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Contact Us")])]), _c('div', [_c('p', [_vm._v("+91 7013870575")]), _c('p', [_vm._v("+91 9051117655")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('div', {
    staticClass: "contact-icon"
  }, [_c('img', {
    attrs: {
      "src": "img/contact/email.png",
      "alt": "email icon"
    }
  }), _c('h6', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Email")])]), _c('div', [_c('p', {
    staticClass: "mt-2"
  }, [_vm._v("info@trigital.in")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "marginleft-137"
  }, [_vm._v(" #502, Techno Residency, Sector 3, Phase 2,Opp to Raheja Mindspace, HITEC City, Hyderabad, Telangana 500081 ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marginleft-70"
  }, [_c('span', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.facebook.com/eparishevaonline/"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/footer/facebook.png",
      "alt": "facebook icon"
    }
  })])]), _c('span', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.instagram.com/eparishevawestbengal/?r=nametag"
    }
  }, [_c('img', {
    staticClass: "mx-2",
    attrs: {
      "src": "img/footer/instagram.png",
      "alt": "instagram icon"
    }
  })]), _c('a', {
    attrs: {
      "href": "https://www.linkedin.com/company/6457757/admin/",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/footer/linkedin.png",
      "alt": "linkedin icon"
    }
  })])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-7 mt-4"
  }, [_c('iframe', {
    staticClass: "location-map",
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.3053107802684!2d78.35052161454755!3d17.492933204357602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9268559aaaab%3A0x730f614f2bb1a3e7!2sTrigital%20Technologies!5e0!3m2!1sen!2sin!4v1631810863084!5m2!1sen!2sin",
      "width": "600",
      "height": "450",
      "frameborder": "0",
      "allowfullscreen": "",
      "aria-hidden": "false",
      "tabindex": "0"
    }
  })]);
}]

export { render, staticRenderFns }