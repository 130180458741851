<template>
  <div>
    <section class="contact-page">
      <h2 class="marginleft-160 font-weight-bold py-5">Contact Us</h2>
      <div class="container">
        <div class="row section-b-space">
          <div class="col-lg-5">
            <div class="contact-right">
              <ul>
                <li>
                  <div class="contact-icon">
                    <img src="img/contact/phone.png" alt="contact icon" />
                    <h6 class="font-weight-bold">Contact Us</h6>
                  </div>
                  <div>
                    <p>+91 7013870575</p>
                    <p>+91 9051117655</p>
                  </div>
                </li>
                <li>
                  <div class="contact-icon">
                    <img src="img/contact/email.png" alt="email icon" />
                    <h6 class="font-weight-bold">Email</h6>
                  </div>
                  <div>
                    <p class="mt-2">info@trigital.in</p>
                  </div>
                </li>
                <li>
                  <div class="contact-icon mt-3">
                    <b-icon-geo-alt variant="danger"></b-icon-geo-alt>
                    <h6 class="font-weight-bold">Address</h6>
                  </div>
                  <div>
                    <p class="marginleft-137">
                      #502, Techno Residency, Sector 3, Phase 2,Opp to Raheja
                      Mindspace, HITEC City, Hyderabad, Telangana 500081
                    </p>
                  </div>
                </li>
                <li>
                  <div class="contact-icon">
                    <b-icon-hand-thumbs-up
                      variant="danger"
                    ></b-icon-hand-thumbs-up>
                    <h6 class="font-weight-bold">Follow us</h6>
                  </div>
                  <div class="marginleft-70">
                    <span>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/eparishevaonline/"
                      >
                        <img
                          src="img/footer/facebook.png"
                          alt="facebook icon"
                        />
                      </a>
                    </span>
                    <span>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/eparishevawestbengal/?r=nametag"
                      >
                        <img
                          src="img/footer/instagram.png"
                          class="mx-2"
                          alt="instagram icon"
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/6457757/admin/"
                        target="_blank"
                      >
                        <img
                          src="img/footer/linkedin.png"
                          alt="linkedin icon"
                        />
                      </a>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-7 mt-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.3053107802684!2d78.35052161454755!3d17.492933204357602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9268559aaaab%3A0x730f614f2bb1a3e7!2sTrigital%20Technologies!5e0!3m2!1sen!2sin!4v1631810863084!5m2!1sen!2sin"
              width="600"
              height="450"
              frameborder="0"
              class="location-map"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
ul {
  list-style-type: none;
}
.contact-page .contact-right ul li .contact-icon {
  position: absolute;
  left: 0;
  width: 130px;
  border-right: 1px solid #ddd;
}
.location-map {
  border: 0;
  height: 350px;
  margin-left: 32px;
  margin-bottom: 100px;
}
</style>
<style lang="scss">
.contact-page {
  .contact-right {
    ul {
      li {
        border-bottom: 1px solid whitesmoke;
        text-align: center;
        padding-bottom: 10px;
        margin-top: 15px;
      }
    }
  }
}
</style>
